<template>
  <div>
    <KalmModal v-if="!showCreatedReportModal" modalTitle="Créer un compte rendu" @close="close">
      <template v-slot:body>
        <div v-if="this.page === 1">
          <div class="input-group input-group-solid mb-3">
            <label class="input-group-text">Nom du CR</label>
            <input v-model="title" class="form-control form-control-solid" type="text" />
          </div>
          <div class="input-group input-group-solid mb-3 mt-3">
            <label class="input-group-text">N°</label>
            <input v-model="reportNumber" class="form-control form-control-solid" type="number" />
          </div>
          <div class="input-group input-group-solid mb-3 mt-3">
            <label class="input-group-text">Date :</label>
            <input v-model="reportDate" class="form-control form-control-solid" type="date" />
          </div>
          <div class="input-group input-group-solid mb-3 mt-3">
            <label class="input-group-text">Prochaine réunion :</label>
            <input v-model="prochaineReunion" class="form-control form-control-solid" type="datetime-local" />
          </div>
          <div class="input-group input-group-solid mb-3 mt-3">
            <label class="input-group-text">En-tête</label>
            <input v-model="reportFileHeader" class="form-control form-control-solid" type="text" />
          </div>
          <div class="input-group input-group-solid mb-3 mt-3">
            <label class="input-group-text">Pied de page</label>
            <input v-model="reportFileFooter" class="form-control form-control-solid" type="text" />
          </div>
          <div class="mb-3">
            <label class="col-form-label" for="description">Informations importantes</label>
            <textarea
              id="description"
              v-model="importantInfo"
              class="form-control"
              style="white-space: pre-wrap"
              type="text"
            ></textarea>
          </div>
          <div class="form-check form-switch mb-3 mt-3">
            <label class="form-check-label" for="switch-planning">Inclure le planning de chantier</label>
            <input
              id="switch-planning"
              v-model="includePlanning"
              class="form-check-input"
              role="switch"
              type="checkbox"
            />
          </div>
          <StatusMultiSelect
            v-model="crIncludedStatusIds"
            :statuses="this.statuses"
            class="mb-3"
            title="Status inclus"
          ></StatusMultiSelect>
          <div class="">
            <label class="col-form-label">Grouper les tâches par :</label>
            <div class="form-check mb-2">
              <input
                id="flexRadioDefault1"
                v-model="iterationMode"
                class="form-check-input"
                name="flexRadioDefault"
                type="radio"
                value="LOT"
              />
              <label class="form-check-label" for="flexRadioDefault1"> LOT </label>
            </div>
            <div class="form-check mb-2">
              <input
                id="flexRadioDefault2"
                v-model="iterationMode"
                class="form-check-input"
                name="flexRadioDefault"
                type="radio"
                value="EMPLACEMENT"
              />
              <label class="form-check-label" for="flexRadioDefault2"> EMPLACEMENT </label>
            </div>
            <div class="form-check mb-2">
              <input
                id="flexRadioDefault3"
                v-model="iterationMode"
                class="form-check-input"
                name="flexRadioDefault"
                type="radio"
                value="CONTACT"
              />
              <label class="form-check-label" for="flexRadioDefault3"> ENTREPRISE </label>
            </div>
          </div>
        </div>
        <div v-if="this.page === 2">
          <div class="flex justify-content-end">
            <button
              class="btn btn-secondary text-center"
              type="button"
              @click="
                this.$apollo.queries.projectContacts.refetch();
                this.formId = Math.random().toString(36).substring(7);
              "
            >
              <span class="bi bi-arrow-clockwise"></span>
              Réinitialiser la liste
            </button>
          </div>
          <ContactsAttendance
            v-model="this.contactAttendance"
            :contacts="this.projectContacts"
            :formId="this.formId"
            :loading="this.$apollo.queries.projectContacts.loading"
            :projectId="this.projectId"
          ></ContactsAttendance>
        </div>
      </template>
      <template v-slot:footer>
        <button class="btn btn-secondary" data-bs-dismiss="modal" type="button" @click="close">Annuler</button>
        <LoadingButton v-if="isSaving"></LoadingButton>
        <button
          v-if="this.page === 1"
          :disabled="!canSave()"
          class="btn btn-primary"
          type="button"
          @click="this.page = 2"
        >
          Intervenants >
        </button>
        <button
          v-else-if="page === 2 && !isSaving"
          :disabled="!canSave()"
          class="btn btn-primary"
          type="button"
          @click="createReport"
        >
          Sauvegarder
        </button>
      </template>
    </KalmModal>
    <ReportCreatedModal v-if="showCreatedReportModal" :report="createdReport" @close="closeReportCreatedModal">
    </ReportCreatedModal>
  </div>
</template>

<script>
import { GET_PROJECT_BY_ID } from "@/graphql/graphql";
import kalmStore from "@/store";
import KalmModal from "@/views/_core/components/KalmModal.vue";
import LoadingButton from "@/views/_core/components/LoadingButton.vue";
import ContactsAttendance from "@/views/contacts/presentation/contacts-attendance/ContactsAttendance.vue";
import { ALL_STATUSES_QUERY } from "@/views/status/data/status_graphql";
import StatusMultiSelect from "@/views/status/presentation/status-select/StatusMultiSelect.vue";
import dayjs from "dayjs";
import { CREATE_REPORT, GET_REPORTS, UPDATE_REPORT } from "../../data/reports_graphql";
import ReportCreatedModal from "./components/ReportCreatedModal.vue";
import { PROJECT_CONTACTS } from "@/views/contacts/data/contacts_graphql";

export default {
  name: "ReportForm",
  apollo: {
    statuses: {
      query: ALL_STATUSES_QUERY,
    },
    projectContacts: {
      query: PROJECT_CONTACTS,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          projectId: this.projectId,
        };
      },
      update(data) {
        if (!this.report) {
          this.contactAttendance = data.contacts?.map((contact) => {
            return {
              contactId: contact.id,
              attendanceStatus: "E",
              isConvoque: false,
            };
          });
        }

        return data.contacts;
      },
      skip() {
        return !this.projectId;
      },
    },
    project: {
      query: GET_PROJECT_BY_ID,
      variables() {
        return {
          id: kalmStore.getters.currentProjectFocus,
        };
      },
    },
  },
  props: {
    initialProjectId: String,
    initialReportNumber: Number,
    taskSortOrder: [String],
    report: Object,
  },
  components: {
    KalmModal,
    ReportCreatedModal,
    ContactsAttendance,
    StatusMultiSelect,
    LoadingButton,
  },
  data() {
    const currentDate = dayjs();
    const nextWeek = currentDate.add(7, "day").set("hour", 8).set("minute", 30);

    return {
      formId: Math.random().toString(36).substring(7),
      projectId: undefined,
      statuses: [],
      page: 1,
      title: "Compte-rendu de chantier",
      reportDate: currentDate.format("YYYY-MM-DD"),
      prochaineReunion: nextWeek.format("YYYY-MM-DDTHH:mm"),
      importantInfo: "",
      includePlanning: false,
      crIncludedStatusIds: [],
      iterationMode: "LOT",
      reportNumber: "",
      reportFileHeader: "",
      reportFileFooter: "",
      projectContacts: [],
      contacts: [],
      contactAttendance: [],
      showCreatedReportModal: false,
      createdReport: undefined,
      isSaving: false,
    };
  },
  mounted() {
    this.initializeData();
  },
  computed: {
    currentProject() {
      return this.project;
    },
    defaultCrIncludedStatusIds() {
      return this.statuses.filter((status) => status.defaultIncluded).map((status) => status.id);
    },
  },
  watch: {
    project: function (newValue) {
      const name = newValue?.name + " - " + this.title;
      this.reportFileFooter = name;
      this.reportFileHeader = name;
    },
    title: function (newValue) {
      const name = this.project?.name + " - " + newValue;
      this.reportFileFooter = name;
      this.reportFileHeader = name;
    },
  },
  methods: {
    getAttendanceStatusForContact(contactId) {
      return this.value.find((contact) => contact.contactId === contactId)?.attendanceStatus;
    },
    isConvoqueInModel(contactId) {
      return this.value.some((contact) => contact.contactId === contactId && contact.isConvoque);
    },
    initializeData() {
      if (this.initialProjectId) {
        this.projectId = this.initialProjectId;
        this.crIncludedStatusIds = this.defaultCrIncludedStatusIds;
        if (this.initialReportNumber) {
          this.reportNumber = this.initialReportNumber;
        }
      }
      if (this.report) {
        this.title = this.report?.title || "Compte-rendu de Chantier";
        this.reportDate = this.report.reportDate;
        // todo check value of this.report.prochaineReunion to avoid timezone issues
        this.prochaineReunion = new dayjs(this.report.prochaineReunion).tz("Europe/Paris").format("YYYY-MM-DDTHH:mm");
        this.importantInfo = this.report.importantInfo;
        this.includePlanning = this.report.includePlanning;
        this.crIncludedStatusIds = this.report.crIncludedStatus?.map((status) => status.id);
        this.iterationMode = this.report.iterationMode;
        this.reportNumber = this.report.reportNumber;
        this.reportFileHeader = this.report.reportFileHeader;
        this.reportFileFooter = this.report.reportFileFooter;
        this.contactAttendance = this.report.projectReportContacts.map((reportContact) => {
          return {
            contactId: reportContact.contactProjectParticipation.contact.id,
            attendanceStatus: reportContact.attendanceStatus,
            isConvoque: reportContact.isConvoque,
          };
        });
      } else {
        this.importantInfo = this.currentProject?.importantInfo;
        this.contactAttendance = this.projectContacts.map((contact) => {
          return {
            contactId: contact.id,
            attendanceStatus: "E",
            isConvoque: true,
          };
        });
      }
    },
    canSave() {
      return !!this.projectId && !!this.reportDate && !!this.reportNumber;
    },
    async createReport() {
      this.isSaving = true;
      const input = {
        projectId: this.projectId,
        title: this.title,
        reportDate: this.reportDate,
        prochaineReunion: this.prochaineReunion,
        importantInfo: this.importantInfo,
        includePlanning: this.includePlanning,
        crIncludedStatusIds: this.crIncludedStatusIds,
        contacts: this.contactAttendance,
        iterationMode: this.iterationMode,
        reportNumber: this.reportNumber,
        reportFileHeader: this.reportFileHeader,
        reportFileFooter: this.reportFileFooter,
        taskSortOrder: this.taskSortOrder,
      };
      if (this.report) {
        try {
          const res = await this.$apollo.mutate({
            mutation: UPDATE_REPORT,
            variables: {
              id: this.report.id,
              input,
            },
            refetchQueries: [
              {
                query: GET_REPORTS,
                variables: {
                  projectId: this.projectId,
                },
              },
            ],
          });
          this.createdReport = res.data.projectReportUpdate.projectReport;
          this.showCreatedReportModal = true;
        } catch (error) {
          console.error("Update report error:", error);
        }
      } else {
        try {
          const res = await this.$apollo.mutate({
            mutation: CREATE_REPORT,
            variables: {
              input,
            },
            refetchQueries: [
              {
                query: GET_REPORTS,
                variables: {
                  projectId: this.projectId,
                },
              },
            ],
          });
          this.createdReport = res.data.projectReportCreate.projectReport;
          this.showCreatedReportModal = true;
        } catch (error) {
          console.error("Create report error:", error);
        }
      }
      this.isSaving = false;
    },
    closeReportCreatedModal() {
      this.close();
      this.showCreatedReportModal = false;
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
